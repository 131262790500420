@import '../../../Asset/variable.scss';

.popupContent {
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

p {
    margin-block-end: 0px !important;
}

.filters {
    max-height: 300px;
    overflow-y: scroll;
    padding: 5px 5px 5px 10px;

    & > p {
        font-weight: 700;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
    }
}

.row {
    display: flex;
    padding: 5px 5px 5px 10px;

    & > div {
        & > label {
            & > span {
                margin-top: 3px;
                margin-left: 10px;
            }
        }
    }
}

.column {
    display: flex;
    margin-top: -5px;
    // padding: 5px 5px 5px 10px;

    & > div {
        & > label {
            & > span {
                margin-top: 3px;
                margin-left: 10px;
            }
        }
    }
}

.map {
    height: 130px;
}

.tripDetails {
    margin-top: 20px;
    .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & > .element {
            display: flex;
            flex-direction: row;

            & > p {
                margin-left: 5px !important;
                margin-block-end: 0px !important;
            }
        }
    }
}

.name{
    font-weight: bold; 
}

.vehicleDetails {
    margin-top: 10px;
    .imageAndName {
        display: flex;
        flex-direction: row;

        .image {
            width: 80px;
            margin-right: 10px;
        }

        .name {
            display: flex;
            flex-direction: column;

            span {
                font-weight: bold;
                margin: 0px !important;
            }
        }
    }

    .imageAndNameSmaller {
        display: flex;
        flex-direction: row;
        width: 100%;

        .image {
            width: 50px;
            margin-right: 10px;
        }

        .name {
            display: flex;
            flex-direction: column;
            width: 100%;

            span {
                font-weight: bold;
                margin: 0px !important;
            }
        }
    }

    .carNo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            p {
                font-weight: bold;
            }
        }

        .plateNo {
            margin-left: 5px;
            border: 1px solid #bbb;
            border-radius: 4px;
            font-weight: bold;
            padding: 0px 4px 0px 4px;
            margin-bottom: 0px !important;
        }

        .unit {
            margin-left: 5px;
            border: 1px solid #bbb;
            background-color: $petrolBlue;
            border-radius: 4px;
            font-weight: bold;
            padding: 0px 5px 0px 5px;
            margin-bottom: 0px !important;
        }
    }

    

    .dataItem {
    
        padding: 7px;
        padding-right: 20px;
        position: relative;
        width: 100%;
        // height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        box-shadow: inset 0 0 0 4px #f6f8fb;
    
      }

    .carNoSmaller {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .rowText {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            p {
                font-weight: bold;
            }

            .plateNo {
                margin-left: 5px !important;
                border: 1px solid #bbb;
                border-radius: 4px;
                font-weight: bold;
                padding: 0px 4px 0px 4px;
                margin-bottom: 0px !important;
            }

            .unit {
                margin-left: 5px !important;
                border: 1px solid #bbb;
                background-color: $petrolBlue;
                border-radius: 4px;
                font-weight: bold;
                padding: 0px 5px 0px 5px;
                margin-bottom: 0px !important;
            }
        }

        
    }

    
}

.selectedTripsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.selectedTripCount {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bloodOrange;
    padding: 1px 10px 1px 10px;
    border-radius: 2px;

    p {
        color: white;
    }
}

.selectedTripsList {
    margin-bottom: 4px;
}

.multipleTripListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FAFAFA !important;
    border: 2px solid white;
    border-radius: 4px;
    padding: 3px 10px 3px 10px;

    p {
        margin-left: 5px !important;
    }
}

.roadIcon {
    width: 25px;
    height: 20px;
}

.checkoutDriverDiv {
    display: flex;
    flex-direction: column;
    padding: 12px 12px;
    width: 100%;
    // margin-bottom: 350px;

    & p {
        font-size: 13px !important;
    }
}

.inputfield{
    font-family: "LeasePlan",Arial,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 600;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1em;
    box-sizing: border-box;
    height: 50px;
    padding: 12px;
    padding-left: 12px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    background-color: #fff;
    border-color: #989a9a;
    color: #000;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.vl {
    border-left: 3px solid $steel60;
    width: 200px;
  }

  .dataResult {
    
    font-size: 15px;
    color:red;
    margin-top: -10px;
    margin-left: 12px;
    width: 93%;
    height: 225px;
    background-color: #e9f0f5;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
    overflow: hidden;
    overflow-y: auto;
  }

  .fontstyle{
    color: #004A5D;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 5px;
    align-content: center;
    cursor: pointer;
  }

  .greyCircle {
    width: 8px;
    height: 8px;
    background: $steel60;
    border-radius: 50%;
}

.orangeCircle {
    width: 8px;
    height: 8px;
    background: $darkOrange;
    border-radius: 50%;
}