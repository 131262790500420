@import '../../../Asset/variable.scss';
.heading {
    margin-bottom: 10px !important;
}


.oneScoreContainer{
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem;
}

.oneScoreCard {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
}

.oneScoreCardsGrid{
    box-sizing: border-box;
    display: grid;
    grid-template-areas: 
        "eventImage"
        "eventName"
    ;
    padding: 1.5rem;
    background-color: white;
    border: 0.5px solid $backgroundBlue;
    border-radius: 3px;
    max-height: 100px;
}

.eventImage{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: eventImage;
    .textFont{
        font-size: 30px;
    }
}

.eventName{
    grid-area: eventName;
    font-size: 10px;
    color: #4f4f4f;
    line-height: 18px;
    text-align: center;
}

.score {
    margin-bottom: 20px;
}
.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    & > p {
        margin-right: 10px;
    }
}

.vehicleIcon {
    width: 40px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.oneScoreTrend{
    box-sizing: border-box;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    height: 20px;

    .trendIcon {
        width: 20px
    }
}

.oneScoreTrendPercentage{
    font-family: LeasePlan;
    display: flex;
    justify-content: flex-start;
    font-size: 8px;
    color: #212121;
    font-style: italic;
}

.MuiBadge-colorPrimary {
    background-color: red !important;
}

.lpLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	height: calc(100vh - 150px);
}