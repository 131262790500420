@import "../../../Asset/variable.scss";

.rightPanelWrapper {
    display: flex;
    flex-direction: column;
}

.filterRow {
    display: flex;
    flex-direction: row;
    background-color: $white;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;;
    border-radius: 5px;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

    & > p {
        margin-bottom: 0px !important;
    }
}

.filterIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    cursor: pointer;
}

.iconFilter {
    width: 1em;
    height: 1em;
}