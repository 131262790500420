@import "../../../Asset/variable.scss";

.eventRow {
    display: flex;
    flex-direction: row;
    background-color: $white;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin: 10px 0px 10px 0px;
    pointer-events: auto;

    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 5px 0 rgba(0, 0, 0, 0.19);

    & > p {
        margin-bottom: 0px !important;
    }
}

.eventColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
}

.eventInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > p {
        margin-bottom: 0px !important;
        font-weight: 800;
    }
}
.vehicleInfo {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > p {
        margin-bottom: 0px !important;
        font-weight: 700;
    }
}

.divider {
    margin: 0px 5px 0px 5px !important;
    font-weight: 800 !important;
}

.vehicleIcon {
    width: 40px;
}

.closeIcon {
    cursor: pointer;
}

.popupContent {
    background: black !important;
}


.filterPopup-content {
    margin: auto;
    background: black;
    width: 50%;
    padding: 5px;
  }
  .filterPopup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].filterPopup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .filterPopup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].filterPopup-overlay {
    background: transparent;
  }