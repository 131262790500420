.shine {
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
  
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  
    height: 10px;
    margin-top: 10px;
    width: 100%;
}

.tableContainer{
  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }

  &::-webkit-scrollbar-track {
      border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #bbb;
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
      box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
  }
}

.iconFilter {
  height: 12px;
  margin-left: 8px;
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}