@import '../../../Asset/variable.scss';

.listItem {
    height: 30px;
    cursor: pointer;
    padding: 10px 0px 10px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;

    & > p {
        font-size: 13px !important;
        margin-block-start: 0px !important;
        margin-block-end: 0px !important;
    }

    &:hover {
        background-color: #dadada;
    }
}

.selectedListItem {
    height: 30px;
    cursor: pointer;
    padding: 10px 0px 10px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;
    background-color: #dadada;

    & > p {
        font-size: 13px !important;
        margin-block-start: 0px !important;
        margin-block-end: 0px !important;
    }

    &:hover {
        background-color: #dadada;
    }
}