@import "../../../Asset/variable.scss";

.Loader__NationalShopPopup {
    display: flex;
    justify-content: center;
}

.GridStyle__PolyLineTooltip {
    grid-template-columns: 1fr 1fr;
}

.vertical {
    border-left: 2px solid rgb(141, 135, 135);
    height: 25%;
    position: absolute;
    margin-top: 1%;
    left: 26%;
}

.eventVerticalLine {
    border-left: 2px solid rgb(141, 135, 135);
    height: 25%;
    position: absolute;
    margin-top: 1%;
    left: 45%;
}

.verticalLine {
    border-left: 2px solid rgb(141, 135, 135);
    height: 25%;
    position: absolute;
    margin-top: 1%;
    left: 56%;
}

.locationVertical {
    border-left: 2px solid rgb(141, 135, 135);
    height: 15%;
    position: absolute;
    margin-top: 1%;
    left: 26.5%;
}

.locationVerticalLine {
    border-left: 2px solid rgb(141, 135, 135);
    height: 15%;
    position: absolute;
    margin-top: 1%;
    left: 57%;
}

.img {
    padding-left: 10px;
    position: absolute;
    width: 20px;
    height: 20px;
    fill: #004a5d;
    cursor: pointer;
}

.textHover {
    cursor: pointer;

    &:hover {
        color: $midOrange;
    }

}