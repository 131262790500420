@import '../../../Asset/variable.scss';

.container {
    // overflow: hidden;
    min-width: 300px;

    &.productName {
        font-size: 15px;
    }
}

.calendarText {
    margin-top: -6px;
}

.selectDiv {
    width: 10em;
    margin-top: -10px;
    height: 27px;
    padding: 4px 8px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    font-family: LeasePlan;
    font-size: 12px;
    color: $petrolBlue;
    text-decoration: none solid rgb(0, 74, 93);
}

.noDataAvailable {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}


.costDisplay {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    padding: 10px;
    margin: 0 30px 0 0;
    font-weight: bold;
    color: $midOrange;
}

.tripTimeCell {
    display: flex;
    flex-direction: row;
    height: 60px;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.tripTime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.blueCircle {
    width: 8px;
    height: 8px;
    background: $ActiveBlue;
    border-radius: 50%;
}

.orangeCircle {
    width: 8px;
    height: 8px;
    background: $darkOrange;
    border-radius: 50%;
}

.vl {
    border-left: 3px solid $darkOrange;
    background: $darkOrange;
    height: 55px;
}

.vl2 {
    border-left: 3px solid $ActiveBlue;
    background: $ActiveBlue;
    height: 55px;
}