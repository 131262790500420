@import "../../Asset/variable.scss";

.searchInputs {
    margin-top: 105px;
    color: #004A5D ;
    display: flex;
    pointer-events: auto;
    overflow-x: auto;
  }


@media (max-width:1149px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .searchTextInput {
  
    overflow: visible;
    color: $primaryText;
    width:25vw;
    height: 2.5rem;
    padding-left: 10%;
    // padding-top: 2%
    padding-right: 5vw;;
    margin-left: 20px;
   box-sizing: border-box;
}
// .selectDiv{
//   margin-right: 1vw;
//   width: auto;
// }
}


@media (min-width:1150px){
  .searchTextInput {
   width:27vw;
}
}
@media (min-width:1200px){
  .searchTextInput {
    width:25vw;
}
}

@media (min-width:1281px){
  .searchTextInput {
    width:23vw;
}
}

@media (min-width:1300px){
  .searchTextInput {
    width:23vw;
}
}

@media (min-width:1501px){
  .searchTextInput {
    width:22vw;
}
}

@media (min-width:1601px){
  .searchTextInput {
    width:20vw;
}
}

@media (min-width:1801px){
  .searchTextInput {
    width:19vw;
}
}

.searchTextInput {
  
  overflow: visible;
  color: $primaryText;
  height: 2.5rem;
  padding-left: 10%;
  margin-left: 20px;
  padding-right: 5.2vw;
  box-sizing: border-box;
}




  
  
  .search input {
    background-color: white;
    padding-top: 20%;
    margin-left: '200px';
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    pointer-events: auto;
    overflow-x: auto;
  }

  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    
    font-size: 12px;
    color:red;
    position: relative;
    // margin-top: 5px;
    // margin-left: 5px;
    width: 100%;
    height: 225px;
    background-color: #e9f0f5;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
    overflow: hidden;
    overflow-y: auto;
    z-index: 1000;
  }

  .fontstyle{
    color: #004A5D;
    font-weight: bold;
  }

  .dataResult1 {
    color: #004A5D;
    font-size: 10px;
    padding-top: 10px;
    margin-top: 1px;
    // margin-left: -10px;
    width: 100%;
    height: 240px;
    background-color: #e9f0f5;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-x: hidden;
    scroll-behavior: unset;
  }


  .dataResult1::-webkit-scrollbar {
    scroll-behavior: unset;
  }
  
  .dataResult .dataItem {
    
    padding: 7px;
    // padding-right: 20px;
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: black;
    box-shadow: inset 0 0 0 4px #f6f8fb;


  }

  .tripSelectIcon{
    width: 23px;
    height: 23px;
    cursor: pointer;
}
 
  
  #clearBtn {
    cursor: pointer;
  }

.mainWrapper {
 
    margin-top: 14px;
    margin-right: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    pointer-events: auto;
    background-color: #f6f8fb;
	  border-radius: 20px;
    align-items: center;
    
}


 .selectDiv{
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    font-family: LeasePlan, Arial, Helvetica, sans-serif;
    margin-left: -5vw;
    position: relative;
    color: #004A5D;
    font-weight: bold;
    border-left-color: #004A5D ;
    border-top: hidden;
    border-bottom: hidden;
    border-right: hidden;
      padding-left: .5vw;
    width: fit-content;
    height: 1.5rem;
    background-color: #f6f8fb !important;
    cursor: pointer;
}


.button{
  cursor: pointer;
}

.lpLoader{
  padding: 2em;
  background-color: #e9f0f5;
}



.SearchIcon{
    position: absolute;
    padding-left: 5px;
    padding-top: 1vh;
    // overflow: hidden;
    overflow-x: auto;
}



// .searchTextInput {
  
//     overflow: visible;
//     color: $primaryText;
//     // margin-right: 6vw;
//     width: 18vw;
//     // font-size: 1.5rem;
//     // line-height: 1.25;
//     // height: 1vmin;
//     // position: relative;
//     padding-left: 10%;
//     // padding-top: 2%;
//     margin-left: 20px;
//     padding-right: 5.2vw;
//     margin-bottom: 5px;
//     box-sizing: border-box;
// }
