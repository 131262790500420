@import "../../Asset/variable.scss";

.graphPaper{
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.15);
    border-radius: 8px;
    padding: 5px;

    .heading{
        text-align: center;
    }

    .cancelButton{
        padding: 7px;
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;

        .cancelIcon{
            color: $petrolBlue;
        }
    }

    .downloadButton{
        padding: 7px;
        position: absolute;
        top: 0px;
        left: 22px;
        cursor: pointer;

        .downloadIcon{
            color: $petrolBlue;
        }
    }


    .favouriteButton{
        padding: 3px;
        position: absolute;
        top: 0px;
        left: 0px;
        cursor: pointer;

        .borderedStar{
            color: $petrolBlue;
        }

        .filledStar{
            color: $midOrange;
        }
    }
}


.addReports{
    width: 300px;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $petrolBlue;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 8px;
}

.iconFilter {
    margin: .2em 3rem;
    width: 1em;
    height: 1em;
    cursor: pointer;
    position: absolute;
}
