.Container__Map{
    position: relative;
}

.Container_Relative{
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    pointer-events: none;
    
}

.Container__Content{
    display: flex;
    flex-direction: column;
    pointer-events: none;
}

.Container__Navbar{
    pointer-events: auto;
}
