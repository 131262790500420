@import '../../../Asset/variable.scss';

.vehicleInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
        margin-block-end: 0px !important;
    }

    & > .vehicleIcon {
        width: 200px;
    }

    & > .carNo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        & > p {
            font-weight: 700;
            margin-block-end: 0px !important;
        }

        & > span {
            margin: 1px 3px 1px 3px;
            border: 1px solid $petrolBlue;
            background-color: $white;
            padding: 0px 3px 0px 3px;
            border-radius: 2px;
            font-weight: 700;
            line-height: 1.5 !important;
        }
    }
}