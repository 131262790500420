@import '../../../Asset/variable.scss';

.mainWrapper {
    padding: 8px 10px 8px 10px;
    width: 93%;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;
    border-bottom: 3px solid white;
}

.driverName {
    color: '#4a4a4a';
    margin-left: 10px !important;
    font-weight: bold !important;
}