.heading {
    margin-bottom: 10px !important;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.hiddenDiv {
    display: none;
}

.downloadIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 10px 5px 5px 5px;

    & > svg {
        width: 1.8rem;
        height: 1.8rem;
    }
}

.calendarIcon {
    z-index: 100;
    color: goldenrod;
    align-content: center;
    margin: 4px -17px 0 -10px;
    font-size: .6rem;
    font-weight: bolder;
    cursor: pointer;
}

.lpLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	height: calc(100vh - 150px);
}