@import "../../Asset/variable.scss";

.mainWrapper {
    margin: 10px;
    box-sizing: border-box;
    width: 80vw;
    height: calc(100vh - 100px);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    pointer-events: auto;
    background-color: #ffffff;
    min-width: 300px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
    }
}

.emptyCards{
    width: 300px;
    height: 230px;
    background-color:#fafafa;;
    box-sizing: border-box;
    margin: 10px;
    padding: 0 !important;
    border-radius: 8px;

    .addReports{
        width: 100%;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed $petrolBlue;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-radius: 8px;

        
        .addReportsButton{
            border: 1px solid $petrolBlue;
            color: $petrolBlue;
            background-color: transparent;
        }
    }
}

.lpLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	height: calc(100vh - 250px);
}
.lploaderSaving{
    position: absolute;
    margin-top: 30vh;
    margin-left: 45vw;
    z-index: 10;   
}
.dropzone {
    height: 2000px;
    width: 300;
    
  }
  .graphRepo{
    // padding-left: 10px;
    width: fit-content;
  }
  .stickyButton{
    z-index: 10;
    position: fixed;
    margin-left: -12em;
  }
  
.CloseButton {
    border: none;
    display: flex;
    outline: none;
    background: none;
    align-items: center;
    justify-content: right;

    & p {
        margin: 0;
    }

    & svg {
        height: 22px;
        width: 22px;
        margin-top: -10em;
        // margin-left: 67em;
        fill: $petrolBlue;

        & use {
            fill: $petrolBlue;
        }
    }

    &:hover:not(:disabled),
    &[data-attr-active="true"] {
        cursor: pointer;

        & svg {
            fill: $midOrange;

            & use {
                fill: $midOrange;
            }
        }

        & p {
            color: $midOrange;
        }
    }

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}