@import '../../../Asset/variable.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background-color: $backgroundBlue;
    margin-bottom: 10px;

    & > p {
        font-size: 14px !important;
        margin-bottom: 5px !important;
    }
}

.tripDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        & > .element {
            display: flex;
            flex-direction: row;
            margin-right: 15px;

            & > p {
                margin-left: 5px !important;
                margin-block-end: 0px !important;
            }
        }
    }
}

.roadIcon {
    width: 25px;
    height: 20px;
}

.tripDuration {
    display: flex;
    flex-direction: row;
    margin-top: 22px;

    & > p {
        margin-block-end: 0px !important;
    }
}

.arrowIcon {
    margin: 0px 5px 0px 5px;
}

//   .tableCell{
//     border-bottom: 1px solid rgba(224, 224, 224, 1);
//     & > span{
//         font-size: 13px;
//     }
// }

.tripTimeCell {
    display: flex;
    flex-direction: row;
    height: 60px;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // padding: 5px;
}

.tripTime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.greyCircle {
    width: 8px;
    height: 8px;
    background: $steel60;
    border-radius: 50%;
}

.orangeCircle {
    width: 8px;
    height: 8px;
    background: $darkOrange;
    border-radius: 50%;
}

.vl {
    border-left: 3px solid $steel60;
    height: 30px;
  }


 