@import "../../../Asset/variable.scss";

  .option .buttons {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .option .buttons button {
    flex-direction: row;
  
    border-radius: 5px;
    padding: 0 1.3rem;
  
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 27px;
    text-align: center;
  
    width: 320px;
    height: 40px;
    left: 50px;
  
    cursor: pointer;
  }
  
  #option1Btn {
    margin-right: 10px;
  }
  
  .selected {
    color: #fff;
    background-color: #00867d;
    border: 1px solid #00867d;
  }
  
  .notSelected {
    color: #00867d;
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
  }