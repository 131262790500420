@import "../../../Asset/variable.scss";

.value1 {
    font-size: 14px;
    font-weight: 700;
  }

.svgStyle {
    width: 2rem;
    height: 2rem;
}
