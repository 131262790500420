@import "../../../Asset/variable.scss";

.cardContainer{
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 1rem ;
}

.graphContainer{
    min-width: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.emptyCards{
  position: relative;
    width:100%;
    height: 250px;
    box-sizing: border-box;
    min-width: 0;
    min-height: 0;
    padding: 1rem ;

    .addReportsButton{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid $petrolBlue;
      color: $petrolBlue;
      background-color: transparent;
  }
}