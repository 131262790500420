.tagBody{
    & > span{
        background: rgb(255,171,53);
        background: linear-gradient(53deg, rgba(255,171,53,0.7455357142857143) 1%, rgba(240,100,0,1) 14%);
        padding: 0.2rem 0.6rem 0.2rem 0.6rem;
        border-radius: 30px;
        font-size: 14px;
        color: white;
        font-weight: 600;
    }
}

.cancelButton{
    font-size: 12px;
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
    
}

.groupTag{
    overflow: scroll;
    overflow-x: hidden;
    max-height: 45px;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
    }
    background-color: white;
    padding: 10px;
}

// .filterType{
//     background-color: white;
//     color: black;
// }

// .filterValue{
//     background-color: rgb(255,171,53);
//     color: white;
// }