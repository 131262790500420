@import '../../../Asset/variable.scss';

.container {
    overflow: hidden;
    min-width: 300px;

    &.productName {
        font-size: 15px;
    }
}

.noDataAvailable {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.tripTimeCell {
    display: flex;
    flex-direction: row;
    height: 11rem;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.greyCircle {
    width: 8px;
    height: 8px;
    background: $steel40;
    border-radius: 50%;
}

.vl {
    border-left: 3px solid $steel40;
    background: $steel40;
    height: 11rem;
}

.iconFilter {
    margin: -0.5rem 0.9rem 2rem 0rem;
    width: 1em;
    height: 1em;
    cursor: pointer;
}

.calendarText {
    margin-top: -6px;
}

.selectDiv {
    width: 10em;
    margin-top: -10px;
    height: 27px;
    padding: 4px 8px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    font-family: LeasePlan;
    font-size: 12px;
    color: $petrolBlue;
    text-decoration: none solid rgb(0, 74, 93);
}