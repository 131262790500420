@import '../../Asset/variable.scss';

.graphGrid {
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2fr 6fr 2fr;
    grid-template-rows: 1fr 12fr 1fr;
    grid-template-areas:
        "leftDrawer bottomDrawer rightDrawer"
        "leftDrawer topDrawer rightDrawer"
        "leftDrawer middleDrawer middleDrawer"

    ;
    grid-gap: 10px;
    width: 100vw;
    height: calc(100vh - 80px);
}

.graphGridHidingLeft {
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2fr 6fr 2fr;
    grid-template-rows: 1fr 12fr 1fr;
    grid-template-areas:

        "bottomDrawer bottomDrawer rightDrawer"
        "topDrawer topDrawer rightDrawer"
        "middleDrawer middleDrawer middleDrawer"

    ;
    grid-gap: 10px;
    width: 100vw;
    height: calc(100vh - 80px);
}

.leftDrawer {
    grid-area: leftDrawer;
    background-color: #f6f8fb;
    pointer-events: auto;
    overflow: scroll;
    overflow-x: hidden;
    min-width: 370px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
    }
}

.topDrawer {
    padding: 10px;
    grid-area: topDrawer;
    background-color: transparent;
    pointer-events: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rightDrawer {
    padding: 10px;
    grid-area: rightDrawer;
    pointer-events: auto;
    overflow: scroll;
    overflow-x: hidden;
    min-width: 300px;
    margin: 1.8em 0;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
    }
}

.middleDrawer {
    padding: 10px;
    margin-bottom: 5em;
    grid-area: middleDrawer;
    margin-right: -2em;
}

.bottomDrawer {
    padding: 10px;
    grid-area: bottomDrawer;
    pointer-events: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 40px;
}

.cardinalIcon {
    padding: 10px;
    float: right;
    width: 3.5em;
}

.dateDetail{
    position: absolute;
    right: 2em;
    background-color: ghostwhite;
    border: solid 1px lightgray;
    padding: 3px 10px 3px 10px;
}