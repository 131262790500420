.leaflet-container{
    z-index: 0;
    height: 100vh;
    width: 100%;
}

.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    color: #004a5d;
}

.leaflet-popup-content-wrapper {
    width: 300px;
}


/*Disabled popup fade in animation so that the component stops glitching*/
.leaflet-fade-anim .leaflet-popup {
    transition: none;
    /* … (browser prefixes as needed) */
}