@import "../../../Asset/variable.scss";

.container {
    box-sizing: border-box;
    width: 100%;

    .graphArea {
        min-width: 0;
        overflow: hidden;
        padding: 0;
    }

    .number {
        text-align: center;
        color: #ED7D31;
        font-weight: bold;
        font-size: xx-large;
    }

    .table {
        background-color: white;
        width: 100%;
        height: 5pc;
        font-weight: bold;
        border-collapse: collapse;

        tr:nth-child(even) {
            background-color: #FCE4D6;
        }

        td,
        th {
            border: 2px solid black;
        }

        th {
            font-size: 16px;
            background-color: #ED7D31;
            height: 2pc;
            color: white;
        }

        td {
            text-align: center;

            font-size: 15px;
            padding: 0;
            margin: 0;
        }
    }
}

.favouriteButton {
    padding-left: 10px;
    position: absolute;
    cursor: pointer;

    .borderedStar {
        color: $petrolBlue;
    }

    .filledStar {
        color: $midOrange;
    }
}

.downloadButton {
    cursor: pointer;
    position: absolute;
    margin: 5px 35px;

    .downloadIcon {
        color: $petrolBlue;
    }
}

.graphPictogramIcon {
    width: 5rem;
    height: 5rem;
    display: flex;
    margin: auto;
    margin-top: 3rem;
}

.graphPictogramText{
    padding: 2%;
    text-align: center;
}