.tableCell{
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    & > span{
        font-size: 13px;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        border-radius: 50%;
        height: 25px;
        margin-right: 5px;
    }
}

.button{
    cursor: pointer;
}

.vehicleRepairSvg{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px 5px 10px 5px;

    .repairSvg{
        font-size: 26px;
        position: absolute;
        left:0;
    }

    .standSvg{
        font-size: 29px;
        position: absolute;
        bottom: -20px;
        left:-2px;
    }
}