@import "../../Asset/variable.scss";
.CommingSoon {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	height: calc(100vh - 67px);
	& > h1 {
		text-transform: uppercase;
		margin-bottom: 0;
		margin-top: 0.5em;
	}
	& > span {
		color: $petrolBlue40;
	}
}
