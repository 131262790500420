@import "../../../Asset/variable.scss";

.modalConfig {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .downloadIcon {
        position: absolute;
        left: 0px;
        top: 0px;
        padding: 15px;
        cursor: pointer;
    }

    .modalHeading {
        text-align: center;
    }

    .iconFilter {
        margin-left: 0.5rem;
        width: 1em;
        height: 1.2em;
        cursor: pointer;
        position: absolute;
    }

    .colorDot {
        position: absolute;
        width: 0.7rem;
        height: .7rem;
        background-color: $midOrange;
        border-radius: 50%;
        margin: -.3em 1.3rem;
    }

    .calendarButton {
        padding: 10px;
        position: absolute;
        top: 5px;
        right: 40px;
        cursor: pointer;
        font-size: 30px;
        color: $midOrange;
    }

    .loader {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}