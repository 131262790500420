@import "../../../Asset/variable.scss";

.tagBody{
        margin: 4px;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 0 0.6rem 0 0.6rem;

        .filterValue{
            font-size: 13px;
            color: $petrolBlue;
            font-weight: 600;
            padding-right: 0.3rem;
            font-family: $fontFamily;
        }

        .filterNumber{
            font-size: 12px;
            color: $petrolBlue;
            font-weight: 900;
            padding-right: 0.1rem;
        }

        .filterDownArrow{
            position: relative;
            bottom: 3px;
            left: 3px;
            cursor: pointer;
        }
}


    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #ffffff;
        border-radius: 10px;
    
        .menuItem {
            text-align: center;
            cursor: pointer;
            padding: 7px;
            border-bottom: 1px solid rgb(187, 187, 187);
            font-family: $fontFamily;
            font-size: 13px;
            font-weight: 600;
            color: #004a5d;
            &:hover {
                color: white;
                background-color: #004a5d;
              }
          }
    }
