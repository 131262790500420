@import '../../../Asset/variable.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background-color: $backgroundBlue;
    margin-bottom: 10px;

    & > p {
        font-size: 14px !important;
    }
}

.tripDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        & > .element {
            display: flex;
            flex-direction: row;
            margin-right: 5px;

            & > p {
                margin-block-end: 0px !important;
            }
        }
    }
}

.roadIcon {
    width: 25px;
    height: 20px;
}

.tripDuration {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    & > p {
        margin-block-end: 0px !important;
    }
}

.arrowIcon {
    margin: 0px 5px 0px 5px;
}