$white: #ffffff;
$black: #000000;
$borderClrGry: #767878;
$borderClrLightGry: #d2d2d2;
$primaryText: #2d2d2d;
$midOrange: #f06400;
$lightSuccess: #dde99f;
$grassGreen: #009a17;
$lightDanger: #fad9d2;
$error: #e7401e;
$bloodOrange: #ee4c14;
$lightOrange: #ed8b00;
$headerGrey: #eeeeee;
$gallery: #eeeeee;
$darkGrey: #bbbbbb;
$backgroundBlue: #f6f8fb;
$Sand: #f6f6f6;
$mist: #f6f8fb;
$ActiveBlue: #457ee7;
$petrolBlue: #044a5d;
$petrolBlue20: #ccdbdf;
$petrolBlue40: #99b7be;
$aquaBlue: #00b1af;
$thunder: #2d2d2d;
$steel: #545656;
$steel20: #dddddd;
$steel40: #bbbbbb;
$steel60: #989a9a;
$red: red;
$BoxShadow: 0px 2px 4px #cecece;
$fontFamily: "LeasePlan", Arial, Helvetica, sans-serif;
$headingDarkBlue: #173d4d;
$darkOrange: #ee8c00;
$lightGreen: #7ccc5a;
$headingText: '#004a5d';
$hardBreaking: '#fddc69';