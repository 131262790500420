@import '../../../Asset/variable.scss';

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 5px 10px 5px;
    background-color: $backgroundBlue;
    margin-bottom: 10px;
    
    border-radius: 5px;

    & > p {
        font-size: 14px !important;
        margin-bottom: 5px !important;
    }

    & > .details {
        flex: 0.95;
        display: flex;
        flex-direction: row;

        & > .left {
            margin-right: 5px;
        }

        & > .right {

        }
    }

    & > .right {
        flex: 0.05;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        & > svg {
            cursor: pointer;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    padding: 5px 0px 5px 0px;
}

.vehicleIcon {
    width: 20px;
}

.divider {
    width: 5px
}

.arrowIcon {
    margin: 0px;
}