@import "../../../Asset/variable.scss";

.leftPanelWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    padding: 10px 10px 5px 10px;
}

.content {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding: 10px;
}

.circle_yellow {
    transition-property: width, height, margin-left, margin-top;
    transition-duration: 2s;
    transform: translateX(-50%) translateY(-50%);
    background-color: #F9C74B;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    color: white;
    box-shadow: 10px 10px 98px 0px #F9C74B;
    -webkit-box-shadow: 10px 10px 98px 0px rgb(255, 251, 0);
    -moz-box-shadow: 10px 10px 98px 0px rgb(255, 230, 0);
  }

  .circle_green {
    transition-property: width, height, margin-left, margin-top;
    transition-duration: 2s;
    transform: translateX(-50%) translateY(-50%);
    background-color: #3cd155;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    color: white;
    box-shadow: 10px 10px 98px 0px #1df700;
    -webkit-box-shadow: 10px 10px 98px 0px rgb(0, 255, 0);
    -moz-box-shadow: 10px 10px 98px 0px rgb(30, 255, 0);
  }

  .circle_red{
    transition-property: width, height, margin-left, margin-top;
    transition-duration: 2s;
    transform: translateX(-50%) translateY(-50%);
    background-color: #ff3d3d;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    color: white;
    box-shadow: 10px 10px 98px 0px #f94b4b;
    -webkit-box-shadow: 10px 10px 98px 0px rgb(255, 17, 0);
    -moz-box-shadow: 10px 10px 98px 0px rgb(255, 8, 0);
  }

  .circle_grey{
    transition-property: width, height, margin-left, margin-top;
    transition-duration: 2s;
    transform: translateX(-50%) translateY(-50%);
    background-color: #929191;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    color: white;
    box-shadow: 10px 10px 98px 0px #464545;
    -webkit-box-shadow: 10px 10px 98px 0px rgb(59, 59, 59);
    -moz-box-shadow: 10px 10px 98px 0px rgb(44, 44, 44);
  }


  .circularContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: 40px;

    & > p {
        margin-bottom: 0px !important;
        line-height: 1.0 !important;
    }

    & > span {
      font-size: 42px !important;
      margin-bottom: 0px !important;
      line-height: 1.0 !important;
    }
  }

  .bottomText {
      margin-top: 10px !important;
  } 

  .generalProgress {
    display: flex;
    flex-direction: row;
    text-align: center;
  }

.progressLabel {
    flex: 33%;
    padding: 15px;
    & > p {
      color: $black;
      font-weight: 800;
    }
}