.popupContent {
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.selectDiv {
    margin-top: .5vh;
    color: #004A5D;
    font-weight: lighter;
    font-family: LeasePlan, Arial, Helvetica, sans-serif;
    width: 48%;
    height: 3vh;
    border-radius: 5px;
    background-color: #f6f8fb !important;
    cursor: pointer;
}

.poiIcon {
    width: 1.5em;
    margin-left: 1.8em;
    padding-top: 0.2em;
    position: absolute;
}

.checkbox {
    margin-left: 1.8em;
}

.settingsIcon {
    width: 16px;
    height: 16px;
    fill: #989a9a;
}

.root {
    display: flex;
    flex-direction: row;
    width: 100%,
}

.href {
    width: 76px;
    height: 17px;
    font-family: LeasePlan;
    font-size: 14px;
    color: #f06400;
    text-decoration: none solid rgb(240, 100, 0);
}

.filterDiv {
    flex: 0.2;
    padding: 0px 10px 0px 10px;
}

.sliderDiv {
    flex: 0.8;
    align-items: center;
    justify-content: center;
}

.playbackControlDiv {
    flex: 0.2;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: space-around;
    //padding: 0px 10px 0px 0px;
}

.locationText {
    padding: 5px 5px;
    margin-top: -2.5em;
}

.stickyButton {
    margin: .1rem 2rem -0.1rem 2rem;
}

.locationIcon {
    margin: .1rem .2rem -1.5rem .5rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.calendarIcon {
    position: absolute;
    margin-left: -5vh;
    color: #ed8b00;
    margin-top: 4px;
    font-size: .6rem;
    font-weight: bolder;
    cursor: pointer;
    z-index: 1000;
}

.tripSelectIcon {
    // position: relative;
    // margin-left: vh;
    width: 25px;
    height: 25px;
    cursor: pointer;
}





.transparentBackground {
    flex: 0.8;
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 0px 10px 0px 10px;
}

.hiddenDiv {
    display: none;
}


.checkoutDriverDiv {
    display: flex;
    flex-direction: row;
    padding: 12px 12px;
    width: 90%;
    // margin-bottom: 350px;

    & p {
        font-size: 13px !important;
    }
}

.messageBar {
    position: absolute;
    margin-left: 15%;
}

.zipButton {
    margin-left: 1em;
}

.inputfield {
    font-family: "LeasePlan", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: .7em;
    box-sizing: border-box;
    height: 35px;
    padding: 12px;
    padding-left: 12px;
    width: 20%;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    color: #004A5D;
    background-color: #fff;
    border-color: #989a9a;
    color: #000;
}

.buttons {
    flex: 20%;
    display: flex;
    align-items: center;
}

.option .buttons button {
    flex-direction: row;
    width: 8em;
    height: 2em;
    cursor: pointer;
}

.selected {
    border-radius: 4px;
    background-color: #004a5d;
    background-size: cover;
    font-family: "LeasePlan", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.notSelected {
    border-radius: 4px;
    background-color: #ffffff;
    background-size: cover;
    font-family: "LeasePlan", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #66929e;
    text-decoration: none solid rgb(102, 146, 158);
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), inset 0px 1px 2px rgba(0, 0, 0, 0.2);
}