@import "../../Asset/variable.scss";

.Field {
  padding: 8px;
}

label {
  & p {
    margin: 0;
  }
  & span {
    font-weight: 600;
    color: $petrolBlue !important;
  }
}

.ButtonGroup {
  right: 8px;
  z-index: 1;
  top: 38px;
  position: absolute;
  & button {
    width: 45px;
    height: 35px;
    outline: none;
    cursor: pointer;
    padding: 0px 8px;
    border-radius: 5px;
    border-style: none;
    // color: $steel60;
    background-color: $white;
    & p {
      margin: 0;
    }
    &.Active {
      & p {
        color: $white;
      }
      background-color: $petrolBlue;
    }
    &:not(.Active) {
      & p {
        color: $steel60;
      }
      // opacity: .4;
    }
  }
}

.InputFieldCurr,
.NormalInputField {
  display: block;
  position: relative;
  
}

.SelectField,
.InputWrapper {
  & label > span {
    font-weight: normal;
  }
}

.SelectField {
  & > div > div > div {
    overflow: hidden !important;
    padding-right: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.RadioGroup {
  & > label > span {
    // margin: 0px 110px 20px 60px !important;
    // margin-left: 50px !important;
    font-size: 60px !important;
  }
  // & subLabel>
}

.PhoneNumberSelectField {
  & > div > div > div {
    overflow: hidden !important;
    padding-right: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 30.2px;
    margin-left: -24px;
    @media only screen and (max-width: 46.25em) {
      margin-left: 0;
    }
  }
}

.InputField,
.InputFieldCurr {
  & input {
    padding-left: 2em;
  }
  &.inputActive > div > div > div:first-child {
    color: $black;
  }
  & > div {
    & > label > span {
      font-weight: normal;
    }
    & > div {
      position: relative;
      & > div:first-child {
        width: 2em;
        z-index: 1;
        display: block;
        color: $steel40;
        line-height: 1.5;
        text-align: right;
        padding-right: 4px;
      }
    }
  }
}

.characterCount{
  position: relative;  

  & textarea{
    height:100%;
    width:100%;
    display:block;
    padding-bottom:40px;
    box-sizing: border-box;

  }

  & > span{
    position: absolute;  
    bottom: 5px;
    right: 30px;
    color: $petrolBlue;
    font-family: LeasePlan;
    font-weight: 800;
  }

}
