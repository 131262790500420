.heading {
    margin-bottom: 10px !important;
}

.lpLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	height: calc(100vh - 150px);
}