@import "../../Asset/variable.scss";

.pickerContainer{
     position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.404);
    z-index: 100;

    .boxCalendar {
        border-radius: 0px;
    }

    .boxCalendarToday{
        background-color: #f064005e;
        border-radius: 0%;
        color: #f06400;
    }

    .boxCalendarSelected{
        border-radius: 0%;
    }
}

.headerComponent{
    height: 100px;
    width: 100%;
    background-color: #f06400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fontFamily;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;

    .headerText{
        font-size: 30px;
        font-weight: 600;
    }

    .headerHypen{
        padding: 0px 10px 0px 10px;
        font-size: 30px;
        font-weight: 800;
    }
}


.footerComponent{
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;

    .buttonStyle{
        color: rgba(0, 0, 0, 0.87);
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        color: #f06400;
        cursor: pointer;

        &.footerOkButton{
            text-align: center;
            padding: 6px 8px;

            &:hover{
                background-color: rgba(240, 238, 238, 0.582);
            }
        }
    
        &.footerCancelButton{
            text-align: center;
            padding: 6px 8px;

            &:hover{
                background-color: rgba(240, 238, 238, 0.582);
            }
        }
    }

    
}