.customTooltip {
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
    width: 100%;

    p {
        margin: 0;
    }

    .label {
        font-weight: bold;
        display: inline-block;
        font-size: 18px;
        padding: 0;
        margin-bottom: 10px;
        color: #044a5d
    }

    .desc_head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;

        .desc {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            width: 100%;
            margin-bottom: 3px;
    
            .value{
                padding: 0px 5px 0 5px;
            }
        }

        .descUtil {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            width: 100%;
            margin-bottom: 3px;
            font-weight: 20;
        }
    }
}