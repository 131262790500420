.loginGrid{
    z-index: 99;
    box-sizing: border-box;
    width: 100vh;
    height: 100vh;
}

.loader{
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

.error{
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
}