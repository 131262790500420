@import "../../Asset/variable.scss";

.mainWrapper {
    margin: 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2fr 9fr;
    grid-template-rows: 1fr 11fr ;
    grid-template-areas: 
        "topHeader topHeader"
        "sideFilter mainTable"
    ;
    width: 95vw;
    height: calc(100vh - 100px);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    pointer-events: auto;
    background-color: #f6f8fb;
    min-width: 300px;
    overflow-x: auto;
}

.header {
    grid-area: topHeader;
    align-items: center;
    justify-content: center;
    background-color: white;
    display: flex;
    flex-direction: row;
}

.heading{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 0px 10px 0px 10px;
    margin: -10px 10px 0px 10px;
    box-sizing: border-box;

    & > .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%
    }
}


.searchTextInput {
    margin-left: 10px;
    padding: 5px;
    box-sizing: border-box;
}

.searchDropdown {
    //background-color: $white;
    color: $petrolBlue !important;
    font-size: 13px !important;
    font-weight: 800 !important;
    padding: 3px 15px 3px 12px;
}

.filters {
    grid-area: sideFilter;
    padding: 15px;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 20px;
    min-width: 200px;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
    }

    .filtersHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 5px;
    
        & > p {
            cursor: pointer;
        }
    }
    
    .filtersBody {
        display: flex;
        flex-direction: column;
    }

    
}


.searchArea{
    grid-area: mainTable;

    .searchAreaMain{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;

        .mainTableHeader{
            max-height: 100px;
            margin: 5px 0 5px 0;
        }
        
        .mainTableBody{
            
        }
    }
}

.filterLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50%;
}