@import "../../Asset/variable.scss";

.mainWrapper {
    margin: 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto auto 10fr;
    grid-template-rows: 1fr 10fr;
    grid-template-areas:
        "topHeader topHeader topHeader"
        "sideDrawer hiddenIcon mainTable"
    ;
    width: 95vw;
    height: calc(100vh - 100px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    background-color: #f6f8fb;
    min-width: 300px;
    overflow-x: auto;
}

.topHeader {
    grid-area: topHeader;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 0.85
    }

    .heading {
        margin: 0px 20px 0px 20px;
        flex: 0.14
    }

    .rightSide {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 20px;

        button {
            height: 40px !important;
        }
    }

    .downloadIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .5rem;
        // padding: 10px;
        cursor: pointer;

        &>svg {
            width: 2rem;
            height: 2rem;
        }
    }

    .calendarIcon {
        cursor: pointer;
        width: 2em;
        height: 1.2em;
    }
}

.searchTextInput {
    margin-left: 10px;
    padding: 5px;
    box-sizing: border-box;
}

.sideDrawer {
    grid-area: sideDrawer;
    z-index: 300px;
    background-color: white;
    margin: 20px 0 40px 0;
    overflow: scroll;
    overflow-x: hidden;
    min-width: 200px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
    }

    .filtersBody {
        display: flex;
        flex-direction: column;
    }
}

.isHiddenIcon {
    grid-area: hiddenIcon;
    box-sizing: border-box;
    z-index: 300px;
    background-color: white;
    margin: 20px 10px 40px 0;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;

    &>svg {
        position: relative;
        color: $petrolBlue;
        font-size: 30px;
        padding: none;

    }
}

.mainTable {
    grid-area: mainTable;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
}

.filterLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100%;
}

.searchDiv {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 0px 0px;
    box-sizing: border-box;

    &>.left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%
    }
}

.calendarIconDate {
    // position: absolute;
    color: #ed8b00;
    align-content: center;
    margin-left: -1.9rem;
    margin-top: .2rem;
    font-size: .7rem;
    font-weight: bolder;
    cursor: pointer;
}

.lploader {
    position: absolute;
    margin-top: 30vh;
    margin-left: 45vw;
    z-index: 10;
}

.CloseButton {
    border: none;
    display: flex;
    outline: none;
    background: none;
    align-items: center;
    justify-content: center;

    & p {
        margin: 0;
    }

    & svg {
        height: 22px;
        width: 22px;
        margin-left: 1em;
        fill: $petrolBlue;

        & use {
            fill: $petrolBlue;
        }
    }

    &:hover:not(:disabled),
    &[data-attr-active="true"] {
        cursor: pointer;

        & svg {
            fill: $midOrange;

            & use {
                fill: $midOrange;
            }
        }

        & p {
            color: $midOrange;
        }
    }

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}