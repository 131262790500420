@import '../../../Asset/variable.scss';

.mainWrapper {
    margin: 20px;
    display: grid;
    grid-template-columns: 400px 3.5fr 40px 3.5fr 2.5fr;
    grid-template-areas: "driverDetails tripDetails collapsedTrip emptySpace latestEvents";
}

.mainWrapperCollapsed {
    margin: 20px;
    display: grid;
    grid-template-columns: 400px 40px 3.5fr 2.5fr;
    grid-template-areas: "driverDetails collapsedTrip emptySpace latestEvents";
}

.driverDetails {
    grid-area: driverDetails;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
    background-color: $white;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    pointer-events: auto;
    height: calc(100vh - 120px);
    max-width: 100%;
    min-width: 17em;

    &>.row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
        margin-top: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
    }
}

.vehicleInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &>span {
        margin-block-end: 0px !important;
    }

    &>.vehicleIcon {
        width: 200px;
    }

    &>.carNo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        &>p {
            font-weight: 700;
            margin-block-end: 0px !important;
        }

        &>span {
            margin: 1px 3px 1px 3px;
            border: 1px solid $petrolBlue;
            background-color: $white;
            padding: 0px 3px 0px 3px;
            border-radius: 2px;
            font-weight: 700;
            line-height: 1.5 !important;
        }
    }
}

.obd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;

    &>.left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &>span {
            font-weight: 700;
            line-height: 1.5 !important;
            margin-block-end: 0px !important;
        }

        &>.icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            border-radius: 50px;
            height: 25px;
            margin-right: 5px;
        }
    }

    &>span {
        line-height: 1.5 !important;
        margin-block-end: 0px !important;
    }
}

.evReady {
    display: flex;
    flex-direction: row;
    background-color: $lightGreen;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;

    &>.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        background-color: $white;
        border-radius: 50px;
        height: 30px;
        margin-right: 5px;
    }

    &>.evDescription {
        display: flex;
        flex-direction: column;

        &>p {
            font-weight: 700;
            margin-block-end: 0px !important;
        }
    }
}

.list {
    margin-top: 10px;
    background-color: #f6f8fb;
}

.centeredDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notAssignedVehicle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 200px;
    margin: 10px 20px 10px 20px;
    padding: 20px 0px 20px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);

    &>.vehicleIcon {
        width: 70px
    }

    &>p {
        margin-top: 10px;
        font-size: 11px !important;
    }
}

.recentPoolDrivers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
}

.accordianSummary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &>.vehicleIcon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    &>p {
        font-weight: 700 !important;
        margin-block-end: 0px !important;
    }
}

.tripDetails {
    grid-area: tripDetails;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
    clip-path: inset(-4px 0px -4px -4px);
    background-color: $white;
    border-radius: 5px 0px 0px 5px;
    padding: 10px;
    overflow-y: scroll;
    pointer-events: auto;
    height: calc(100vh - 120px);

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
    }
}

.collapsedTrip {
    grid-area: collapsedTrip;
    background-color: $white;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
    clip-path: inset(-5px -5px -5px 0px);
    border-radius: 0px 5px 5px 0px;
    padding: 10px;
    pointer-events: auto;
    height: calc(100vh - 120px);
    cursor: pointer;

    &>.hiddenIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 120px);

        &>svg {
            position: relative;
            color: $petrolBlue;
            font-size: 30px;
            padding: none;

        }
    }
}

.emptySpace {
    grid-area: emptySpace;
}

.latestEvents {
    grid-area: latestEvents;
    overflow-y: scroll;
    padding: 0px 5px 0px 5px;
    pointer-events: auto;
    height: calc(100vh - 180px);

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5);
    }
}

.hiddenDiv {
    display: none;
}

.cardinalIcon {
    padding: 10px;
    position: absolute;
    pointer-events: auto;
    width: 3.5em;
    bottom: 7em;
    right: -0.5em;
}

.dateDetail{
    position: absolute;
    right: 2em;
    background-color: ghostwhite;
    border: solid 1px lightgray;
    padding: 3px 10px 3px 10px;
}