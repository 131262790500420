.Loader {
    padding: 1em;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    & svg {
        font-size: 5em;
        margin-bottom: 8px;
    }
    // & span {
    //     opacity: .3;
    // }
}