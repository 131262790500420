.ErrorPage {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    line-height: 2;
    flex-direction: column;

    & .ErrorIcon {
            height: 60px;
            width: 60px;
    }
}