@import "../../../Asset/variable.scss";

.tableCell{
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    & > span{
        font-size: 13px;
    }
}

.button{
    cursor: pointer;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    p {
        margin-left: 5px !important;
    }
}

.tripTimeCell {
    display: flex;
    flex-direction: row;
    height: 60px;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.tripTime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.greyCircle {
    width: 8px;
    height: 8px;
    background: $steel60;
    border-radius: 50%;
}

.orangeCircle {
    width: 8px;
    height: 8px;
    background: $darkOrange;
    border-radius: 50%;
}

.vl {
    border-left: 3px solid $steel60;
    height: 30px;
  }

.poi {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    label {
        margin-top: 2px;
    }
    .list {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
    }
}

.showMore {
    cursor: pointer;
}