@import "../../../../Asset/variable.scss";

// $errorClr: $error;

.Toast {
    align-items: flex-start;
    // border-radius: 4px;
    color: #ffffff;
    display: flex;
    padding: 14px 10px;
    border-left: 4px solid;
    // background-color: #000;

    & > svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

// .Toast:not(:first-child) {
.Toast {
    margin: 0 0 12px;
}

// .Toast__content {
//     flex: 1 1 auTo;
//     margin: 0 12px 0 0;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

.Toast__dismiss {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: block;
    flex: 0 0 auTo;
    font: inherit;
    padding: 0;
}

.Toast__content {
    flex: 1;
    & > *:first-child {
        text-transform: capitalize;
    }
}