@import "../../../Asset/variable.scss";




.searchInputs {
    margin-top: 105px;
    color: #004A5D ;
    display: flex;
    pointer-events: auto;
    overflow-x: auto;
  }



.searchTextInput {
    width:24em;
  overflow: visible;
  color: $primaryText;
  height: 2.5rem;
  padding-left: 10%;
  margin-left: 20px;
//   padding-right: 5.2vw;
  box-sizing: border-box;
}




  
  
  .search input {
    background-color: white;
    padding-top: 20%;
    margin-left: '200px';
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    pointer-events: auto;
    overflow-x: auto;
  }

  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    
    font-size: 12px;
    // margin-left: 10px;
    margin-top: -1vh;
    // color:red;
    position: relative;
    // margin-top: 5px;
    // margin-left: 5px;
    width: 95%;
    height: 22.5em;
    background-color: #DADADA;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
  }

  .fontstyle{
    color: #004A5D;
    font-weight: bold;
    // border-radius: 50%;
  }

  .dataResult1 {
    color: #004A5D;
    font-size: 10px;
    padding-top: 10px;
    margin-top: 1px;
    // margin-left: -10px;
    width: 100%;
    height: 240px;
    background-color: #e9f0f5;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-x: hidden;
    scroll-behavior: unset;
  }


  .dataResult1::-webkit-scrollbar {
    scroll-behavior: unset;
  }
  
  .dataResult .dataItem {
    
    padding: 7px;
    // padding-right: 20px;
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    color: black;
    box-shadow: inset 0 0 0 4px #f6f8fb;


  }

  .tripSelectIcon{
    width: 23px;
    height: 23px;
    cursor: pointer;
}
 
  
  #clearBtn {
    cursor: pointer;
  }

.mainWrapper {
    position: relative;
    margin-top: 14px;
    margin-right: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    pointer-events: auto;
    background-color: #f6f8fb;
	  border-radius: 20px;
    align-items: center;
    z-index: 500;
    
}


.iconFilter {
    width: 1em;
    height: 1em;
    position: absolute;
    left: 22.5em;
    top: 2em;
} 


.button{
  cursor: pointer;
}



.SearchIcon{
    position: absolute;
    padding-left: 5px;
    padding-top: 1vh;
    // overflow: hidden;
    margin-top: 2;
    overflow-x: auto;
    z-index: 500;
}

.title{
  font-size: 12px;
  padding: 5px;
    margin-left: 16px;
    // margin-top: 5.5em;
    color:#004A5D;
    position: relative;
    width: 85%;
    height: 20px; 
    overflow: hidden;
    overflow-y: auto;
    z-index: 1000;
}

.title2{
  font-size: 12px;
  padding: 5px;
    margin-left: 16px;
    color:#004A5D;
    position: relative;
    width: 85%;
    height: 15px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1000;
}

.test{
    padding-top: 4rem;
}