@import "../../Asset/variable.scss";

.HeaderWrapper {
    position: relative;
    background-color: $white;
    height: 70px;
    display: flex;
    border-bottom: 1px solid #e2e4e5;

    .HeaderLeft {
        display: flex;
        flex: 3;
        overflow: hidden;

        .Logo {
            // width: 300px;
            // height: 10px;
            flex: 1 0 0px;
            text-align: center;

            // padding-left: 150px;
            img {
                // position: absolute;
                // transform: scale(1);
                // top: 0px;
                height: 3.5rem;
                margin-top: 0.5em;
                margin-left: 2.5rem
            }
        }

        nav {
            display: flex;
            flex: 5;
            // margin-left: 30px;
        }

        .Menu {
            display: flex;
            align-items: center;

            .MenuItem {
                list-style: none;
                padding: 10px 15px;
                text-transform: capitalize;

                a {
                    color: $primaryText;
                    font-size: 14px;
                    font-weight: 700;
                    text-decoration: none;

                    &:hover {
                        color: $midOrange;
                    }
                }
            }
        }
    }

    .stickyButton {
        position: absolute;
        margin-top: 10vh;
        margin-left: 45vw;
        z-index: 2;
    }

    .notify {
        position: absolute;
        margin-left: 76px;
        margin-top: -15px;
        z-index: 2;
        color: $midOrange;
        font-family: LeasePlan, Arial, Helvetica, sans-serif;
        font-style: normal;
        font-size: 1em;
        font-weight: bold;
    }

    .searchIcon {
        width: 1.5em;
        height: 1.4em;
        fill: #004a5d;
        margin-top: 1.2em;
        margin-right: 1em;
        border: 2px solid lightgrey;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
    }

    .lploader {
        position: absolute;
        margin-top: 10vh;
        margin-left: 45vw;
        z-index: -1;
    }

    .lploaderLog {
        position: absolute;
        margin-top: 40vh;
        margin-left: 70vw;
    }

    .MenuItem2 {
        margin-right: 20px;
    }

    .HeaderRight {
        flex: 1;
        display: flex;
        // font-size: 14px;
        align-items: center;
        justify-content: flex-end;

        div {
            display: flex;
            cursor: pointer;
            padding: 5px 15px;
            align-items: center;
            justify-content: center;

            &:not(:last-child) {
                border-right: 1px solid $borderClrGry;
            }

            &:hover {
                & p {
                    color: $midOrange;
                }
            }

            & svg {
                margin-right: 8px;
                font-size: 20px;
                color: $midOrange;

                & use {
                    fill: $midOrange;
                }
            }

            & p {
                margin: 0;
            }
        }

        li {
            list-style-type: none;

            a {
                font-family: LeasePlan, Arial, Helvetica, sans-serif;
                font-style: normal;
                font-size: 13px;
                font-weight: 700;
                -webkit-font-smoothing: antialiased;
                -webkit-box-align: center;
                align-items: center;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                flex-direction: column;
                -webkit-box-flex: 1;
                flex-grow: 1;
                padding: 0px 2px;
                text-decoration: none;
                color: $midOrange;

                &.active {
                    border-bottom-color: $midOrange;
                }
            }
        }
    }
}