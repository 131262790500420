@import "../../Asset/variable.scss";

.CloseButton {
    border: none;
    display: flex;
    outline: none;
    background: none;
    align-items: center;
    justify-content: center;
    & p {
        margin: 0;
    }
    & svg {
        height: 22px;
        width: 22px;
        fill: $petrolBlue;
        & use {
            fill: $petrolBlue;
        }
    }
    &:hover:not(:disabled),
    &[data-attr-active="true"] {
        cursor: pointer;
        & svg {
            fill: $midOrange;
            & use {
                fill: $midOrange;
            }
        }
        & p {
            color: $midOrange;
        }
    }
    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.BackButton {
    border: none;
    display: flex;
    outline: none;
    background: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & p {
        margin: 0;
    }
    & svg {
        height: 22px;
        width: 22px;
        margin-right: 5px;
        transform: rotate(180deg);
        fill: $midOrange;
            & use {
                fill: $midOrange;
            }
    }
    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.ActionBtnContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -2px 4px #cecece;
    & > div {
        padding: 10px;
    }
}

label > span {
    font-weight: normal !important;
}

.customWidth {
    top: 200px;
    width: 13em;
	height: 100%;
    overflow: auto;
    background-color: red;
}

.test{
   top: 200px;
}
