.toasts {
    & > div {
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        transition: width 2s, height 4s;
    }
}

.toastrWrapper {
    top: 75px;
    z-index: 2000;
    right: 24px;
    width: 395px;
    position: fixed;
    height: calc(100% - 100px);
    // bottom: 24px;
    // overflow: auto;
}