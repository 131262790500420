@import "../../../Asset/variable.scss";

.popupContent {
    padding: 5px 15px 5px 15px;
    border-radius: 5px;

    & > p {
        margin-block-end: 0em !important;
    }
}

label {
    & span {
        font-size: 13px !important;
        line-height: 1.8 !important;
        padding-left: 0px !important;
        font-family: 700 !important;
        color: $petrolBlue !important;
    }

    & div {
        width: 18px !important;
        height: 18px !important;
        border: 1px solid $petrolBlue !important;
    }

    & > input:checked ~ div:after {
        top: 0px !important;
        left: 5px !important;
    }
}

.checkboxList {
    margin-left: 10px;
    // max-height: 200px;
    // overflow-y: scroll;
    background-color: white;
    padding: 10px;
    padding-left: 20px;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
        box-shadow: inset 0 0 6px rgba(121, 119, 119, 0.5); 
    }
}

.searchInput {
    height: 30px !important;
    margin: 0 0 10px 10px;
    font-size: 12px !important;
}