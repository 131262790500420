.heading {
    margin-bottom: 10px !important;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > .right {
        width: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & > img {
            cursor: pointer;
        }
        & > svg {
            cursor: pointer;
        }
    }
}

.hiddenDiv {
    display: none;
}

.calendarIcon {
    z-index: 100;
    color: goldenrod;
    align-content: center;
    margin: 4px -25px 0 -10px;
    font-size: .6rem;
    font-weight: bolder;
    cursor: pointer;
}

.iconFilter {
    width: 1em;
    height: 1em;
}

.lpLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	height: calc(100vh - 150px);
}